// FEEDBACK (CONTACT) FORM COMPONENT

.contact-form {
    &_field, &_btn {
        width: 100%;
    }
    &_field {
        font-size: inherit;
        line-height: inherit;
        padding: 17px 20px;
        border: 1px solid $gray;
        margin-bottom: 30px;
        color: $blackalt;
        transition: all .3s ease-in-out;
        &[data-type="message"] {
            margin-bottom: 0;
            min-height: 164px;
        }
        &::placeholder {
            font-size: inherit;
            line-height: inherit;
            color: $gray;
            transition: opacity .3s ease-in-out;
        }
        &:focus {
            border-color: $blackalt;
            &::placeholder {
                opacity: 0;
            }
        }

    }
    &_btn {
        margin-top: 40px;
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 575.98px) {
    .contact-form {
        &_field {
            &--half {
                width: calc(50% - 15px);
            }
        }
    }
}
