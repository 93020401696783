// BLOG SECTION

.blog {
    &_btn {
        margin: 15px 0;
        width: 100%;
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 575.98px) {
    .blog {
        &_btn {
            width: unset;
        }
    }
}

@media screen and (min-width: 767.98px) {
    .blog {
        &_btn {
            margin: 0;
        }
    }
}
