 * {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   outline: none;
 }

 html {
     scroll-behavior: smooth;
 }

 html, body {
   overflow-x: hidden;
 }

 ul {
   list-style: none;
 }

 a {
   text-decoration: none;
 }

 a, input, button, textarea {
   background: none;
   color: inherit;
   font-family: inherit;
   border: none;
   cursor: pointer;
 }

 textarea {
     resize: none;
 }