// FAQ SECTION

.faq {
    &_header {
        .text {
            color: #162739;
        }
        .btn {
            width: 100%;
        }
    }
}

@media screen and (min-width: 575.98px){
    .faq {
        &_header {
            .btn {
                width: unset;
            }
        }
    }
}


@media screen and (min-width: 991.98px) {
    .faq {
        &_header {
            max-width: 472px;
            margin-right: 30px;
            .btn {
                margin: 0;
            }
        }
    }
}
