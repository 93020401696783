// FAQ SECTION

.faq {
    &_header {
        margin-bottom: 40px;
        .text {
            margin: 30px 0 40px;
        }
        .btn {
            width: 100%;
        }
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 575.98px) {
    .faq {
        &_header {
            .btn {
                width: unset;
                margin: 0;
            }
        }
    }
}

@media screen and (min-width: 991.98px) {
    .faq {
        &_header {
            .btn {
                margin: 0 auto;
            }
        }
    }
}

@media screen and (min-width: 1199.98px) {
    .faq {
        &_header {
           max-width: 472px;
            margin-top: 137px;
            margin-right: 30px;
        }
        &_accordion {
            flex-shrink: 1;
            max-width: 740px;
        }
    }
}