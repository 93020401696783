@mixin font-face($name, $file, $weight, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    src: local("#{$file}"),
    url('../fonts/#{$file}.woff2') format('woff2'),
    url('../fonts/#{$file}.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin archExtra() {
  font-family: "Archivo", sans-serif;
  font-weight: 800;
}

@mixin archBold() {
  font-family: "Archivo", sans-serif;
  font-weight: 700;
}

@mixin archSemi() {
  font-family: "Archivo", sans-serif;
  font-weight: 600;
}