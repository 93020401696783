// TEAM SECTION

.team {
    &_header {
        .title {
            max-width: 615px;
        }
    }

    &_btn {
        width: 100%;
        margin-top: 20px;
    }

    &_video {
        max-height: 500px;
        aspect-ratio: 13 / 5;
        overflow: hidden;
        position: relative;
        margin: 40px 0 30px;
        &-thumb {
            width: 100%;
        }
    }

    &_main {
        font-size: 16px;
        line-height: 24px;

        &-text {
            text-align: justify;
            word-break: break-all;
        }

        &-list {
            margin: 30px 0;

            &_item {
                margin-bottom: 15px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .icon {
                    color: $accent;
                    margin-right: 10px;
                }
            }
        }
    }

    &_quote {
        .quote-icon {
            display: none;
        }
        &-wrapper {
            padding: 30px;
            background: radial-gradient(123.37% 123.37% at 5.3% 9.03%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.4) 99.92%);
            backdrop-filter: blur(84px);
            color: $black;
        }

        &-quote {
            quotes: none;
            @include archBold;
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 21px;
            display: inline-block;
        }

        &-author {
            .wrapper--info {
                margin-left: 22px;
            }

            &_avatar {
                border-radius: 50%;
                display: block;
                width: 100%;
                min-width: 53px;
                max-width: 106px;
            }

            &_name, &_profession {
                display: inline-block;
                width: 100%;
            }

            &_profession {
                font-weight: 500;
            }
        }
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 575.98px) {
    .team {
        &_btn {
            width: unset;
            margin-left: 0;
        }
        &_quote {
            &-wrapper {
                padding: 60px;
            }

            &-quote {
                font-size: 26px;
                line-height: 31px;
            }
            &-author {
                font-size: 19px;
                line-height: 28px;
                &_icon {
                    width: 83px;
                    height: 73px;
                }
            }
        }
    }
}

@media screen and (min-width: 767.98px) {
    .team {
        &_main {
            font-size: 19px;
            line-height: 28px;
        }
        &_quote {
            .quote-icon {
                height: 73px;
                width: 83px;
                display: inline-block;
            }
        }
    }
}

@media screen and (min-width: 991.98px) {
    .team {
        &_btn {
            margin-bottom: 5px;
        }
        &_main {
            &-list {
                margin: 30px 0 0;
            }
        }
        &_quote {
            padding-left: 30px;
        }
    }
}

@media screen and (min-width: 1199.98px) {
    .team {
        &_video {
            margin-bottom: 40px;
        }
        &_main {
            padding-right: 58px;
            &-list {
                margin: 40px 0 0;
            }
        }
        &_quote {
            padding-left: 0;
        }
    }
}
