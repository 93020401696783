@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.woff') format('woff'),
    url('../../fonts/icomoon.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_left:before {
  content: "\e901";
}
.icon-arrow_down:before {
  content: "\e913";
}
.icon-comment:before {
  content: "\e914";
}
.icon-inbox:before {
  content: "\e912";
}
.icon-minus:before {
  content: "\e903";
}
.icon-plus:before {
  content: "\e904";
}
.icon-arrow_right:before {
  content: "\e909";
}
.icon-arrow_up:before {
  content: "\e911";
}
.icon-chevron_right:before {
  content: "\e902";
}
.icon-check:before {
  content: "\e90a";
}
.icon-play:before {
  content: "\e900";
}
.icon-call:before {
  content: "\e90c";
}
.icon-location:before {
  content: "\e90b";
}
.icon-star:before {
  content: "\e90e";
}
.icon-bookmark:before {
  content: "\e90f";
}
.icon-search:before {
  content: "\e910";
}
.icon-whatsapp:before {
  content: "\e905";
}
.icon-twitter:before {
  content: "\e906";
}
.icon-instagram:before {
  content: "\e907";
}
.icon-facebook:before {
  content: "\e908";
}







