// FEEDBACK (CONTACT) SECTION

.contact {
    &_form {
        &-header {
            margin-bottom: 30px;
        }
    }
    &_info {
        margin-top: 60px;
        &-header {
            max-width: 340px;
            margin-bottom: 30px;
        }
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 991.98px){
    .contact {
        &_info {
            margin-top: 0;
            &-header {
                margin-bottom: 40px;
            }
            .socials {
                margin-bottom: 5px;
            }
        }
    }
}

@media screen and (min-width: 1199.98px){
    .contact {
        &_form {
            margin-right: 140px;
        }
    }
}
