// SERVICES SECTION

.services {
    &_header {
        margin-bottom: 30px;
        max-width: 510px;
        width: 100%;
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 991.98px) {
    .services {
        &_header {
            margin-bottom: 40px;
        }
    }
}
