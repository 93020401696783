// PROJECTS SECTION

.projects {
    &_header {
        margin-bottom: 30px;
    }
    &_list {
        &-item {
            margin-top: 30px;
            &[data-order="1"] {
                margin-top: 0;
            }
            .wrapper {
                height: 100%;
            }
            &_title {
                flex-grow: 1;
                margin-top: 20px;
            }
            .text-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-grow: 1;
            }
            .img-wrapper {
                overflow: hidden;
                max-height: 500px;
                aspect-ratio: 5 / 4;
                width: 100%;
                position: relative;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    display: block;
                    object-fit: cover;
                    transition: transform .5s ease-in-out;
                }
                &:hover img {
                    transform: scale(1.1);
                }
            }
            &_info {
                position: relative;
                margin-top: 15px;
                padding-top: 15px;
                .separator {
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background: $gray;
                    top: 0;
                    left: 0;
                }
                .location {
                    width: 100%;
                    margin-bottom: 15px;
                    .icon {
                        color: $accent;
                        font-size: 24px;
                        margin-right: 10px;
                    }
                }
                .link-arrow {
                    margin-right: auto;
                }
            }
        }
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 575.98px) {
    .projects {
        &_list {
            &-item {
                &_info {
                    .location {
                        width: unset;
                        margin-right: 15px;
                        margin-bottom: 0;
                    }
                    .link-arrow {
                        margin-left: auto;
                        margin-right: unset;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 767.98px) {
    .projects {
        &_list {
            margin: 0 -15px;
            &-item {
                padding: 0 15px;
                &[data-order="2"] {
                    margin-top: 0;
                }
                &_info {
                    .location {
                        margin-bottom: 15px;
                    }
                    .link-arrow {
                        margin-left: unset;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 991.98px) {
    .projects {
        &_header {
            margin-bottom: 40px;
        }
        &_list {
            margin: 0 -20px;
            &-item {
                padding: 0 20px;
                margin-top: 50px;
                &_info {
                    .location {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1199.98px) {
    .projects {
        &_list {
            &-item {
                &_info {
                    .location {
                        width: unset;
                        margin-right: 15px;
                        margin-bottom: 0;
                    }
                    .link-arrow {
                        margin-left: auto;
                        margin-right: unset;
                    }
                }
            }
        }
    }
}
