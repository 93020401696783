// CONTACT LIST COMPONENT

.contact {
    &-info {
        max-width: 285px;
        margin-bottom: 40px;
        &_group {
            font-family: $accentFont;
            margin-bottom: 30px;

            &:last-of-type {
                margin-bottom: 0;
            }

            span {
                display: block;
            }

            .name {
                text-transform: uppercase;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 8px;
                color: $gray;
                font-weight: 500;
            }

            .content {
                font-weight: 600;
                font-size: 18px;
                line-height: 23px;

                .link {
                    transition: color .3s ease-in-out;

                    &:hover, &:focus {
                        color: $secondary;
                    }
                }
            }
        }
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 575.98px) {
    .contact {
        .socials {
            &_item {
                &-link {
                    width: 50px;
                    height: 50px;
                    font-size: 26px;
                    line-height: 30px;
                }
            }
        }
    }
}

@media screen and (min-width: 991.98px) {
    .contact {
        &-info {
            margin-bottom: 87px;
            &_group {
                margin-bottom: 40px;
            }
        }
    }
}
